import React from "react";

import CallToAction from "../components/call-to-action";
import Contact from "../components/contact";
import Footer from "../components/footer";
import Metadata from "../components/metadata";

const ContactPage = () => {
  return (
    <>
      <Metadata title="Contact - StandupWizard" />
      <Contact />
      <CallToAction />
      <Footer />
    </>
  );
};

export default ContactPage;
